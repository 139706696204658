<template>
  <div id="app">
    <header class="px-3 my-5 " id="myTopnav">
      <b-navbar class="header " type="dark" variant="dark" fixed="top">
        <b-navbar-brand>
          <img class="logo" src="./assets/rlogo-2.png" width="150px" alt="img1">
        </b-navbar-brand>
        <router-link to="/">
          <b-icon icon="house" variant="danger"></b-icon>
        </router-link>
        <!-- <a class="float-right ml-auto"  href="https://quepedimos.co/login"><font-awesome-icon class="text-danger" :icon="['fas', 'border-all']" /></a> -->

        <b-dropdown id="dropdown-dropleft" dropleft  class="ml-auto">
          <template #button-content>
            <img  class="bandera mr-2" :src="getImgUrl()"  width="30px" alt="Panama">
          </template>
          <b-dropdown-item @click="cambiaurl(urlPanama, 'panama.png')"><img class="bandera mr-2" src="./assets/panama.png" width="30px" alt="Panama">Panama</b-dropdown-item>
          <b-dropdown-item  @click="cambiaurl(urlColombia, 'colombia.png')"><img class="bandera mr-2" src="./assets/colombia.png" width="30px" alt="Colombia">Colombia</b-dropdown-item>
          <b-dropdown-item @click="cambiaurl(urlCostaRica, 'costa-rica.png')"><img class="bandera mr-2" src="./assets/costa-rica.png" width="30px" alt="Costa_Rica">Costa Rica</b-dropdown-item>
        </b-dropdown>
      </b-navbar>
    </header>
    <div v-if="errored">
      <p>Lo sentimos, no es posible obtener la información en este momento, por favor intente nuevamente mas tarde</p>
    </div>
    <div v-else class="">
      <router-view></router-view>
    </div>
    <div class="footer-basic">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import axios from 'axios'
export default {
  name: 'App',
  data () {
    return {
      urlPanama: 'https://quepedimos.co/', //
      urlColombia: 'https://co.quepedimos.co/',
      urlCostaRica: 'https://cr.quepedimos.co/',
      urlIMGPrincipal: 'panama.png',
      restaurantes: null,
      isModalVisible: false,
      loading: true,
      errored: false,
      form: {
        email: '',
        name: '',
        food: null,
        checked: []
      },
      foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },
  components: {
    Footer
  },
  mounted () {
    this.cargarData()
    if (this.$cookies.isKey('pais')) {
      this.$hostname = this.$cookies.get('pais')
      this.urlIMGPrincipal = this.$cookies.get('pais_img')
    } else {
      this.$hostname = this.urlPanama
      this.$cookies.set('pais_img', 'panama.png')
    }
  },
  methods: {
    getImgUrl () {
      return require(`./assets/${this.urlIMGPrincipal}`)
    },
    cambiaurl (url, img) {
      this.$hostname = url
      this.$cookies.set('pais', url)
      this.$cookies.set('pais_img', img)
      this.urlIMGPrincipal = img
      this.$router.go()
    },
    cargarData () {
      var config = {
        method: 'get',
        url: this.$hostname + 'api/get_resturents/1/1',
        headers: {}
      }
      axios(config)
        .then(response => {
          this.restaurantes = response.data.all_resturants.data
          this.$restaurantes = response.data.all_resturants.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    },
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    }
  }
}
</script>

<style>
#app {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #2a2d37;
}

.busqueda {
  background-color: #2a2d37;
  color: white;
}

input::placeholder {
  color: white;
}
.dropdown-menu{
  background-color: #2a2d37 !important;
}
.bandera {
  text-align: center;
}
.navbar.navbar-dark.bg-dark {
  background-color: #2a2d37 !important;
  padding-left: 10%;
  padding-top: 10px;
}

.card {
  border-radius: 25px;
  background-color: #393d49;
}

p,
a,
h1,
h2,
h3,
h4 {
  color: white !important;
}

h5 {
  color: #ff0025;
  font-weight: bold;
}

.btn-info {
  background-color: #ff0025;
  padding: 10px;
  border: none;
}

.btn-info:hover {
  background-color: #c10529;
}

.logo {
  text-align: center;
}

.copy {
  text-align: center;
  color: white;
}

@media screen and (max-width: 600px) {
  #myTopnav {}
}
</style>
