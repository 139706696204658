<template>
<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <b-carousel
          id="carousel-fade"
          img-height="300">
          <b-carousel-slide
            v-bind:img-src="restaurante ? restaurante.info.info.gallary : ''"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="mb-4 mt-sm-5 titulo-res row">
      <div class="mx-auto col">
        <h1 class="titulo-restaurante">{{restaurante ? restaurante.info.info.name : ''}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="mx-auto col-md-2">
        <b-button v-b-modal.modal-reserva class="btn btn-sm btn-block btn-info ">¡Solicitud Reserva!</b-button>
      </div>
    </div>
    <div class="mt-5 row d-flex ">
      <div class="col-md-4">
        <div class="card d-flex">
          <gmap-map
              class="m-3"
              :zoom="14"
              :center="center"
              style="width:90%;  height: 250px;"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
              }"
            >
          <GmapMarker ref="myMarker"
            :position="google && new google.maps.LatLng(restaurante.info.info.location.latitude, restaurante.info.info.location.longitude)" />
          </gmap-map>
          <div class="card-body mapa">
              <h5 class="card-title"></h5>
              <p class="card-text"><font-awesome-icon class="mr-2 text-danger" :icon="['fas', 'map-marker']" />{{ restaurante ? restaurante.info.info.about.address_line: ''}} <br>
                <b-icon class="mr-2" icon="globe"></b-icon><a target="_blank" >Web Site</a> <br>
                <font-awesome-icon class="mr-2 " :icon="['fas', 'phone-square']" /><a target="_blank" :href="'tel://+' + restaurante ? restaurante.info.info.about.phone1 : ''">{{restaurante?restaurante.info.info.about.phone1 : ''}}</a> <br>
                <font-awesome-icon class="mr-2 " :icon="['fas', 'envelope']" /><a target="_blank" :href="'mailto://+' + restaurante ? restaurante.info.info.about.email1 : ''">{{restaurante ? restaurante.info.info.about.email1 : ''}}</a> <br>
                <bold class="card-text"  v-if="restaurante.info.info.about.horario_servicio">
                <b-icon  class="mr-2" icon="clock"></b-icon>{{restaurante.info.info.about.horario_servicio}}
                </bold>
                <bold class="card-text"  v-else>
                <b-icon  class="mr-2" icon="clock"></b-icon>Martes a Sábado, 12MD - 11PM
                </bold>
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body d-flex ">
              <h5 class="card-title"></h5>
              <p v-html="restaurante.info.info.about.description" class="card-text">
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
              <h5 class="card-title"></h5>
              <div class="card-text"><h3>Calificaciones</h3>
                <star-rating read-only v-model="restaurante.info.info.avg_ratting" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="20" /> <br><br>
                <h2>Estrellas</h2>
                <font-awesome-icon class="float-left mr-2 text-light" :icon="['fas', 'utensils']" /><h5 style="float: left;margin-right:10px;color:white;" >Comida</h5><star-rating  read-only v-model="fixedRating" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="20" /><br>
                <font-awesome-icon class="float-left mr-2 text-light" :icon="['fas', 'concierge-bell']" /><h5 style="float: left;margin-right:10px;color:white;" >Servicios </h5> <star-rating read-only v-model="fixedRating" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="20" /><br>
                <font-awesome-icon class="float-left mr-2 text-light" :icon="['fas', 'dollar-sign']" /><h5 style="float: left;margin-right:10px;color:white;" >Costo-Beneficio</h5> <star-rating read-only v-model="fixedRating" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="20" />
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5 ">
    <div v-if="restaurante.info.info.reviews.length > 0" class="mt-4 row">
      <div class="mx-auto col-md-4">
        <h3><b>¿Qué dicen los clientes?</b></h3>
      </div>
      <div class="mx-auto col-md-6">
        <Reviews
          v-for="(review, id) in restaurante.info.info.reviews"
          :key="id"
          :review="review"
        />
      </div>
    </div>
  </div>
  <div id="formulario" class="container">
    <b-modal cancel-disabled="true" @ok="recaptcha" id="modal-reserva" title="Solicitar Reserva" >
      <div class="row">
        <b-calendar
          class="p-2 mx-auto"
          v-model="form.fecha"
          :min="min"
          locale="es-ES"
          calendarLocale="es-ES"
          selected-variant="danger"
          today-variant="danger"
          nav-button-variant="dark"
          hide-header="true"
          @selected="cambioFecha"></b-calendar>
      </div>
      <div class="form-group">
        <select class="input-formulario" v-model="form.number_guests">
          <option value='0' selected>Numero Invitados</option>
          <option value='1' selected>1 Persona</option>
          <option v-for="n in restaurante.info.info.limite_puestos_reserva-1" :key="n" value='1'>{{ n+1 }} Personas</option>
        </select>
      </div>
      <p class="ml-4" v-if="fechaSeleccionada">Horario de este dia:<br> {{horarioDiaSeleccionado}}</p>
      <div class="form-group">
        <select class="input-formulario" v-model="form.hora">
          <option selected value='Hora'>Hora</option>
          <option value='12:00:00' >12 PM</option>
          <option value='12:30:00' >12:30 PM</option>
          <option value='13:00:00' >1 PM</option>
          <option value='13:30:00' >1:30 PM</option>
          <option value='14:00:00' >2 PM</option>
          <option value='14:30:00' >2:30 PM</option>
          <option value='15:00:00' >3 PM</option>
          <option value='15:30:00' >3:30 PM</option>
          <option value='16:00:00' >4 PM</option>
          <option value='16:30:00' >4:30 PM</option>
          <option value='17:00:00' >5 PM</option>
          <option value='17:30:00' >5:30 PM</option>
          <option value='18:00:00' >6 PM</option>
          <option value='18:30:00' >6:30 PM</option>
          <option value='19:00:00' >7 PM</option>
          <option value='19:30:00' >7:30 PM</option>
          <option value='20:00:00' >8 PM</option>
          <option value='20:30:00' >8:30 PM</option>
          <option value='21:00:00' >9 PM</option>
          <option value='21:30:00' >9:30 PM</option>
          <option value='22:00:00' >10 PM</option>
          <option value='22:30:00' >10:30 PM</option>
          <option value='23:00:00' >11 PM</option>
          <option value='23:30:00' >11:30 PM</option>
          <option value='00:00:00' >12 AM</option>
        </select>
      </div>
      <div v-if="restaurante.info.info.costo_reserva > 0" class="form-group">
        <label class="input-formulario"
          >Costo Reserva: ${{ restaurante.info.info.costo_reserva }}
        </label>
      </div>
      <div v-if="form.fecha !== null">
        <p class="form-group input-formulario"><b>Datos Básicos:</b></p>
        <input class="input-formulario" type="text" v-model="form.name" placeholder="Nombre"> <br>
        <input class="input-formulario" type="text" v-model="form.lastname" placeholder="Apellido"> <br>
        <input class="input-formulario" type="text" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete="off" v-model="form.client_email" placeholder="Correo Electrónico"> <br>
        <input class="input-formulario" type="text" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete="off" v-model="form.client_email2" placeholder="Repetir Correo"> <br>
        <input class="input-formulario" type="text" v-model="form.phone" placeholder="Teléfono"> <br>
        <input class="pb-5 input-formulario" type="text" v-model="form.message" placeholder="Mensaje">
      </div>
      <template #modal-footer="{ ok }">
      <b-button :disabled="(form.client_email !== form.client_email2) || form.client_email ==='' " size="md" variant="danger" @click="ok() " >
        Enviar Solicitud
      </b-button>
    </template>
    </b-modal>
  </div>
  <div class="container">
    <b-modal v-model="modalShow" ok-title = "OK" cancel-disabled="true"  id="modal-confirmacion" title="" >
      <div class="row">
        <div class="d-flex justify-content-center col">
          <font-awesome-icon class="text-success" style="width:80px;height: 80px;" :icon="['fas', 'check-circle']" />
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center col">
          <h3 class="">Recibida</h3>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center col">
          <p  class="">¡Gracias por tu solicitud!<br>En este momento está siendo procesada, agradecemos verificar su correo electrónico para recibir su confirmación.</p>
        </div>
      </div>
      <div v-if="restaurante.info.info.costo_reserva > 0" class="row">
        <div class="d-flex justify-content-center col">
          <p  class="">Recuerde que la reserva tiene un costo de ${{restaurante.info.info.costo_reserva}}<br>Este monto debe ser pagado con yappy, de clic en el siguiente link para proceder con el pago.</p>
        </div>
      </div>
      <div v-if="restaurante.info.info.costo_reserva > 0" class="row">
        <div class="d-flex justify-content-center col">
          <a target="_blank" class="btn btn-primary" :href="urlPagoYappy">Pagar</a>
        </div>
      </div>
    </b-modal>
  </div>
  <div class="container">
    <b-modal v-model="modalShowNosepuede" ok-title = "OK" cancel-disabled="true"  id="modal-error" title="Error al generar solicitud" >
      <div class="row">
        <div class="d-flex justify-content-center col">
          <h3 class="">No se puede enviar</h3>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center col">
          <p  class="">El restaurante no permite solicitud de reserva en la fecha y hora seleccionada</p>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import Reviews from './components/Reviews.vue'
import StarRating from 'vue-star-rating'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'
export default {
  name: 'Restaurant',
  data () {
    return {
      restaurante: null,
      isModalVisible: false,
      loading: true,
      errored: false,
      minDate: null,
      form: {
        client_email: '',
        client_email2: '',
        phone: '',
        name: '',
        lastname: '',
        birth_date: '',
        number_guests: 0,
        fecha: null,
        hora: 'Hora',
        fecha_reserva: '',
        mesa_id: null,
        message: '',
        user_id: null
      },
      slug: String,
      url: null,
      show: true,
      fixedRating: 4,
      center: {
        lat: 9.0191044,
        lng: -79.4898114
      },
      fechaSeleccionada: false,
      horarioDiaSeleccionado: '',
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      modalShow: false,
      modalShowNosepuede: false,
      urlPagoYappy: ''
    }
  },
  components: {
    StarRating,
    Reviews
    // datetime: Datetime
  },
  mounted () {
    if (this.$cookies.isKey('pais')) {
      this.$hostname = this.$cookies.get('pais')
    }
    this.traerRestaurante()
    if (this.restaurante) {
      this.center.lat = this.restaurante.info.info.location.latitude
      this.center.lng = this.restaurante.info.info.location.longitude
    }
    this.addLocationMarker()
  },
  methods: {
    /* async recaptcha () {
      // (optional) Wait until recaptcha has been loaded.
      console.log('recaptcha clicked')
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      console.log('recaptcha clicked')
      console.log(token)
    },
    template: '<button @click="recaptcha">Execute recaptcha</button>', */
    recaptcha () {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha('login').then((token) => {
          console.log(token) // Will print the token
          this.enviarFormulario()
        })
      })
    },
    cambioFecha (ymd, date) {
      var tmp = moment(ymd)
      const res = this.restaurante.info.info.shopday.find(dia => dia.day === tmp.format('dddd').toLowerCase())
      if (res.status_reserva && res.status_reserva === 1) {
        this.horarioDiaSeleccionado = 'Apertura: ' + res.opening_reserva + ' Cierre: ' + res.close_reserva
        this.fechaSeleccionada = true
      } else {
        this.horarioDiaSeleccionado = 'Restaurante Cerrado este dia'
        this.fechaSeleccionada = true
      }
    },
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    traerRestaurante () {
      this.slug = this.$route.params.slug
      var config = {
        method: 'get',
        url: this.$hostname + 'api/restaurant/' + this.slug
      }
      axios(config)
        .then(response => {
          this.restaurante = response.data
          this.url = this.$hostname + 'store/' + this.restaurante.info.info.slug
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    },
    enviarFormulario () {
      var FormData = require('form-data')
      var data = new FormData()
      var tmp = moment(this.form.fecha)
      const res = this.restaurante.info.info.shopday.find(dia => dia.day === tmp.format('dddd').toLowerCase())
      if (res.status_reserva === 1) {
        if (this.form.hora > res.opening_reserva && this.form.hora < res.close_reserva) {
          var datetime = moment(tmp.format('YYYY-MM-DD') + ' ' + this.form.hora).format('YYYY-MM-DD H:mm:ss')
          data.append('client_email', this.form.client_email)
          data.append('client_phone', this.form.phone)
          data.append('client_name', this.form.name)
          data.append('status', 1)
          data.append('client_lastname', this.form.lastname)
          data.append('number_guests', this.form.number_guests)
          data.append('fecha_reserva', datetime)
          data.append('message', this.form.message)
          data.append('user_id', this.restaurante.info.info.id)
          var config
          if (this.restaurante.info.info.costo_reserva > 0) {
            config = {
              method: 'post',
              url: this.$hostname + 'api/internal_reservation_payment',
              data: data,
              mode: 'no-cors'
            }
          } else {
            config = {
              method: 'post',
              url: this.$hostname + 'api/internal_reservation',
              data: data,
              mode: 'no-cors'
            }
          }
          axios(config, this.form)
            .then(response => {
              if (response.data.pago) {
                if (response.data.pago.success) {
                  this.urlPagoYappy = response.data.pago.url
                }
              }
              this.modalShow = true
              this.limpiarForm()
            })
            .catch(error => {
              console.log(error)
              this.errored = true
              this.modalShowNosepuede = true
            })
            .finally(() => { this.loading = false })
        } else {
          this.horarioDiaSeleccionado = 'Fuera de Horario'
          this.fechaSeleccionada = true
          this.modalShowNosepuede = true
        }
      } else {
        this.horarioDiaSeleccionado = 'Fuera de Horario'
        this.fechaSeleccionada = true
        this.modalShowNosepuede = true
      }
    },
    limpiarForm () {
      this.form.fecha = null
      this.form.hora = 'HORA'
      this.form.client_email = ''
      this.form.name = ''
      this.form.lastname = ''
      this.form.number_guests = ''
      this.form.message = ''
    },
    initMarker (loc) {
      this.existingPlace = loc
    },
    addLocationMarker () {
      if (this.existingPlace) {
        const marker = {
          lat: this.restaurante.info.info.location.latitude,
          lng: this.restaurante.info.info.location.longitude
        }
        this.locationMarkers.push({ position: marker })
        this.locPlaces.push(this.existingPlace)
        this.center = marker
        this.existingPlace = null
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        }
      })
    }
  }
}
</script>

<style scoped>
.modal-title{
  margin: 0 auto;
  color:gray;
}
.mapa{
  padding-top: 0 !important;
}
/deep/ .modal-content{
  background-color:#2a2d37 !important;
  border-radius:25px !important;
  color:white;
}
/deep/ .modal-title{
  margin-left:auto;
}
/deep/ .modal-header, /deep/ .modal-footer{
      border: 0 !important;
}
/deep/ .modal-footer .disabled{
  display:none !important;
}
/deep/ .modal-footer .btn-primary{
  margin-right: auto;
  margin-left: auto;
  padding-left:35%;
  padding-right:35%;
  background-color:#ff0025;
}
.modal-footer .btn{
  margin-right: auto;
  margin-left: auto;
  padding-left:35%;
  padding-right:35%;
  background-color:#ff0025;
}
.modal-header .close{
    margin: -1rem -1rem -1rem 0 !important;
}
.texto-formulario{
  width:140px;
  color:#ff0025;
}
.input-formulario{
  width:90%;
  background-color:#4d5260 !important;
  color:white;
  margin:5% 5% 5% 5%;
  padding: 10px;
  border: 0;
  border-radius: 5px;
}
/deep/ .b-calendar-grid, .b-calendar-grid-body ,.b-calendar-grid-help,.b-calendar-header,.b-calendar-header .form-control{
  background-color:#2a2d37 !important;
  color:white !important;
}
/deep/ .text-dark, /deep/ .b-icon, /deep/ col span {
  color:white !important;
}
/deep/ .b-calendar-grid-help{
  display:none;
}
.vdatetime-popup__header, .vdatetime-calendar__month__day--selected span span{
  background-color:#2a2d37 !important;
  color:white;
}
.vdatetime-popup__actions__button{
  color:#ff0025;
}
.vdatetime-input{
    margin-left: -10px;
    padding-left: 15px;
    width: 102%;
    color: gray;
}
.col-md-4 .card{
  border-radius: 25px;
  height:500px;
}
.card-text a{
  color: dimgray;
  vertical-align: middle;
}
.titulo-restaurante{
  text-align: center;
  color: #ff0025;
  font-weight: bold;
}
.carousel-inner{
  height:350px !important;
  object-fit: fill ;
}

@media screen and (max-width: 600px) {
  .carousel-inner{
    height:200px !important;
  }
  .titulo-res{
    margin-top:0px !important;
  }
  .b-calendar{
  }

  .col-md-4 .card{
    margin-bottom:2%;
    border-radius: 25px;
    height:auto;
  }
}
</style>
