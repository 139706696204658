<template>
  <div >
    <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        background="#2a2d37"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
      <b-carousel-slide>
        <template #img>
          <img  src="./assets/banner/b1.jpg" class="banner d-block w-100" alt="img1">
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img src="./assets/banner/b2.jpg" class="banner d-block w-100" alt="img1">
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img  src="./assets/banner/b3.jpg" class="banner d-block w-100" alt="img1">
        </template>
      </b-carousel-slide>
    </b-carousel>
    <section v-if="errored">
      <p>
        Lo sentimos, no es posible obtener la información en este momento, por
        favor intente nuevamente mas tarde
      </p>
    </section>
    <section class="main mb-4" v-else>
      <div class="container">
        <div class="row">
          <div
            class="py-4 mt-4 col-12 col-sm-12 col-md-12 col-lg-6 d-flex flex-row-start"
          >
            <h3 class="card-title">¡Reserva y disfruta!</h3>
          </div>
          <div
            class="py-4 mt-4 col-12 col-sm-12 col-md-12 col-lg-6 d-flex flex-row-end"
          >
            <div class=" input-group">
              <input
                class="py-4 border busqueda form-control border-right-0"
                type="search"
                placeholder="Buscar Restaurante, zona, tipo de comida"
              />
              <div class="input-group-append ">
                <div style="background-color:#2a2d37;color: white;" class="input-group-text" id="btnGroupAddon2">
                  <a ><b-icon icon="search" style="color: white;"></b-icon></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row restaurant-list">
        <Restaurants
          v-for="(restaurante, id) in restaurantes"
          :key="id"
          :restaurante="restaurante"
        />
      </div>
    </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Restaurants from './components/Restaurants.vue'
export default {
  name: 'Home',
  data () {
    return {
      restaurantes: null,
      newers: null,
      soldests: null,
      offers: null,
      isModalVisible: false,
      loading: true,
      errored: false,
      show: true,
      slide: 0,
      sliding: null
    }
  },
  components: {
    Restaurants
  },
  mounted () {
    this.pullRestaurants()
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    pullRestaurants () {
      if (this.$cookies.isKey('pais')) {
        this.$hostname = this.$cookies.get('pais')
      }
      var config = {
        method: 'get',
        url: this.$hostname + 'api/get_resturents/-1/1',
        headers: {}
      }
      axios(config)
        .then((response) => {
          this.restaurantes = response.data.all_resturants
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    },
    pullItems () {
      var config = {
        method: 'get',
        url: this.$hostname + 'api/newer_items',
        headers: {}
      }
      axios(config)
        .then((response) => {
          this.newers = response.data.newers.slice(0, 3)
          this.soldests = response.data.soldests.slice(0, 3)
          this.offers = response.data.offers.slice(0, 3)
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>
input::placeholder{
  color:white;
}
.banner{
  object-fit: cover ;
}
.img-producto{
  width:100px;
  height:100px;
}
@media screen and (max-width: 600px) {
  .banner{
    width:100%;
  }
}
</style>
