<template>
  <footer class="py-5">
    <div class="logo mt-4 pt-4"><img src="../assets/rlogo-2.png" width="300px" alt="img1"></div>
    <p class="copyright py-4">© Copyright 2022 2Reserva. Todos los derechos reservados.</p>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  }
}
</script>

<style scoped>

footer{
background-image: url('~@/assets/bg-footer.jpg');
}

.footer-basic {
padding:0px 0;
background-color:#ffffff;
color:#4b4c4d;
}

.footer-basic ul {
padding:0;
list-style:none;
text-align:center;
font-size:18px;
line-height:1.6;
margin-bottom:0;
}

.footer-basic li {
padding:0 10px;
}

.footer-basic ul a {
color:inherit;
text-decoration:none;
opacity:0.8;
}

.footer-basic ul a:hover {
opacity:1;
}

.footer-basic .social {
text-align:center;
}

.footer-basic .social > a {
font-size:24px;
width:40px;
height:40px;
line-height:30px;
display:inline-block;
text-align:center;
border-radius:50%;
border:1px solid #ccc;
margin:0 8px;
color:#fff;
opacity:0.75;
}

.footer-basic .social > a:hover {
opacity:0.9;
}

.footer-basic .copyright {
text-align:center;
font-size:13px;
color:white;
margin-bottom:0;
}
</style>
