<template>
  <div class="col-12 col-sm-6 col-lg-3 ">
    <div class="mb-4 card">
        <img class="px-3 pt-3 card-img-top" v-bind:src="restaurante.restaurant.preview.content" alt="img1">
        <div class="card-body  d-flex flex-column">
            <h5 class="card-title">{{restaurante.restaurant.name}}</h5>
            <div class="card-text"><br>
            <div class="row">
              <div class="col " v-for="(categoria,index) in restaurante.restaurant.shopcategory" :key="index">
                <p class="text-nowrap " syle="color:white;">- {{categoria.name}}</p>
              </div>
            </div>
            {{ciudad}}
            <star-rating read-only :v-model="restaurante.restaurant.avg_ratting.content" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="20" />
            <font-awesome-icon class="mr-2 mt-4" :icon="['fas', 'compass']" /> <b> {{restaurante.restaurant.about.address_line}} </b></div>
            <router-link class="mt-auto btn btn-sm btn-block btn-info align-self-end  btn-block " :to="'/restaurant/' + restaurante.restaurant.slug">¡Reserva Ya!</router-link>
            <!-- <a v-b-modal.modal href="#" class="mt-4 btn btn-sm btn-block btn-info">eliminame!</a> -->
        </div>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
  name: 'Restaurants',
  props: ['restaurante'],
  data () {
    return {
      ciudad: null
    }
  },
  components: {
    StarRating
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style scoped>
.card{
  height:530px;
}
.reservar{
  margin-bottom:-10px;
}
.card-text{
  color:white ;
}
.card-img-top{
  /*border-radius: 20px;*/
  object-fit: contain !important;
}
</style>
