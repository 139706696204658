import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './Home.vue'
import Restaurant from './Restaurant.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/restaurant/:slug',
    name: 'restaurant',
    component: Restaurant
  }
]
const router = new VueRouter({
  mode: 'hash',
  routes
})
export default router
