<template>
<div class="mb-2 card">
    <div class="card-body">
    <p class="card-text"><small>{{date}}</small><br></p>
      <div class="row">
        <div class="col-4">
          <img :src="imgURL">
          <p class="card-title">{{review.client_info.name}} {{review.client_info.lastname}}</p>
        </div>
        <div class="col-8">
          <p class="mt-3 mb-4 card-text">{{review.comment_meta.comment}}<br></p>
          <star-rating read-only v-model="review.comment_meta.star_rate" :increment="0.01" :fixed-points="1" :round-start-rating="false" :show-rating="false" :star-size="25" />
        </div>
      </div>
    </div>
</div>
</template>

<script>
import StarRating from 'vue-star-rating'
import moment from 'moment'

export default {
  name: 'Reviews',
  props: ['review'],
  components: {
    StarRating
  },
  data () {
    return {
      date: null,
      imgURL: null
    }
  },
  mounted () {
    this.date = moment(this.review.created_at).format('YYYY-MM-DD')
    this.imgURL = this.review.client_info.avatar.includes('quepedimos.co') ? this.review.client_info.avatar : 'https://quepedimos.co/' + this.review.client_info.avatar
  },
  methods: {
  }
}
</script>

<style scoped>

img{
  width:100px;
  height:100px;
  border-radius:50px;
}
.card-img-top{
  height:150px;
  object-fit: cover !important;
}
</style>
