import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import router from './router'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCompass, faMapMarker, faPhoneSquare, faEnvelope, faUtensils, faConciergeBell, faDollarSign, faBorderAll, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueCompositionAPI from '@vue/composition-api'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6Ld7piIfAAAAAIK9jRoO2xkyHYp6qmIcYzS5mgN6' })

library.add(faCompass)
library.add(faMapMarker)
library.add(faPhoneSquare)
library.add(faEnvelope)
library.add(faUtensils)
library.add(faConciergeBell)
library.add(faDollarSign)
library.add(faBorderAll)
library.add(faCheckCircle)
Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
Vue.use(require('vue-cookies'))
Vue.use(BootstrapVueIcons)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDsjNHKpWWf88hKmikMjUABOwEg3Fx9bpA',
    libraries: 'places'
  }
})
// axios.defaults.baseURL = 'http://quepedimos.loc/api/'
Vue.prototype.$hostname = 'https://quepedimos.co/'
Vue.prototype.$restaurantes = null
axios.defaults.headers.common['Api-Token'] = '$2y$10$RpzGkTB7p50nOOkLjZgsq.agG.Rf5FJhKDegCLzFLISZlKCRu4ZSi'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
